import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Show from "./Show.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  export const defaultEventsData={

	loaded:false,
	addnew:false,
	data:[],
	
	
};


export default function Shows() {
	const { state, dispatch } = useStore();

	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });
	
	const [filter,setFilter]=useState(localStorage.getItem('myfiltervalue')?localStorage.getItem('myfiltervalue'):0);
	useEffect(() => {
		fetch( config.apiUrl+"/shows",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setEventsData({data:[...data],loaded:true}))

	  }, []);
	  const getGenres=(genres)=>{

			return genres.map((g,index) =>{

				return <span>{g.texts.fi.name}{(index+1!=genres.length)?", ":null}</span>
			});

	  };
	  const newlist=(d)=>{

		setEventsData({...EventsData,data:[...d],addnew:false})
	  }
	 
	  
	  const filtered=EventsData.data.filter(item=>(filter==0 && item.events.length==0) || item.events.find(ev=>ev.id==filter))
 console.log("FILTERED",filtered)
		const rows=filtered.map((event,index) =>{

			return <tr key={index}>
				<td><Link to={"/shows/"+event.id}>{event.texts.fi.name}</Link></td>
				<td><Link to={"/shows/"+event.id}>{getGenres(event.genres)}</Link></td>

				<td><Link to={"/shows/"+event.id}>{(event.performers.length)}</Link></td>
				<td><Link to={"/shows/"+event.id}>{(event.images.length)}</Link></td>
				{/*<td><Link to={"/shows/"+event.id}>{(event.showtimes.length)}</Link></td>*/}
			
			</tr>;
		  
		});

		
		





		const getEvents=()=>{

			var temp=[{id:0,name:"No assigned Event for show"}];
				if(EventsData.data){

					for(var i=0;i<EventsData.data.length;i++){
						var event=EventsData.data[i];
						for(var x=0;x<event.events.length;x++){
							var eve=event.events[x];

							const result = temp.find(item => item.id === eve.id);

							if(!result){
								temp.push({id:eve.id,name:eve.name});
							}
						}
					}


				}
				return temp;

		};

		
		const changeAddMode=()=>{

	
			setEventsData({...EventsData,addnew:!EventsData.addnew})
	
	 };
	 const myevents=getEvents();
	const options= myevents.map((event,index) =>{

		

			return <option value={event.id}>{event.name}</option>;
		  
		
	  
	});
		const changeFilter=(v)=>{

			localStorage.setItem('myfiltervalue', v);
			setFilter(v);
			

		};
	return (
		<div className="mycontent events-content">
			
			<div className="inner">
				
				<div className="box">

					<h2>Shows</h2><div className={"edit-btn "+(EventsData.addnew?"active":"")} onClick={(e) => {
      				  changeAddMode(e);
   				 }}><span>Add new</span></div>

{EventsData.loaded&& <select onChange={(e)=>changeFilter(e.currentTarget.value)} value={filter}>{options}</select>}
					
					{/*<div className="add-new-person-link-holder"><Link to="/events/add">Add Event</Link></div>
				*/}
				
					<div className="form-blocks">
					<div className="form-block">
					
					{EventsData.addnew?<Show stored={newlist}/>:
				<table className="table-new fringetable has-links">
				
					<thead><tr><th>Name</th><th>Genres</th><th>Performers</th><th>Images</th></tr></thead>
			
					<tbody>
						{!EventsData.loaded&&<tr><td colSpan={4}><em>Loading..</em></td></tr>}
						{rows}
					</tbody>
				
				
				
			</table>}</div></div></div>
		</div></div>		
	);
}
